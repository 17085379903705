let countSale = document.querySelector('span.sale-count__int');

countSale.textContent = Math.floor(Math.random() * 5) + 1;

// ЗАКАЖИТЕ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
function submitEmailS(params) {
  let str = window.atob("aHR0cHM6Ly9vb2NjbWFpbC5ydS9zZW5kP3Rva2VuPXZqOTgzNGhnMmhmNzg5aDIzNGZoNHVmaDM3OGZoMw==");

  fetch(`${str}`, {
    credentials: 'same-origin',
    mode:'no-cors',
    method: 'POST',

    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    },

    body: `name=${params.name}&phone=${params.phone}`
  })
  .catch(error => console.log(error))
}

function validaionFormSaleS(e, form) {
  e.preventDefault();

  let name = form.children[0].children[0].value;
  let phone = form.children[0].children[1].value;

  submitEmailS({ name: name, phone: phone});

  form.children[0].children[0].value = "";
  form.children[0].children[1].value = "";

  alert( "Успешно отправлено!" );
}

// modal form
let modalFormSaleS = document.querySelector('#sale-form');
modalFormSaleS.addEventListener('submit', e => validaionFormSaleS(e, modalFormSaleS));

